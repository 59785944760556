import { addDays, getDay } from 'date-fns';
import { default as capitalizeFunc } from 'lodash/capitalize';
import pluralize from 'pluralize';

export class TimeUtils {
  static DurationFormattedHHMMSS(
    ms: number | null | undefined,
    includeHours = false
  ): string {
    if (ms === null || ms === undefined) ms = 0;
    const seconds = ms / 1000;
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(Math.floor(seconds % 60)).padStart(2, '0');
    return `${includeHours ? `${hours}:` : ''}${mins}:${secs}`;
  }

  /**
   * Formats a duration in milliseconds to HH:MM:SS if duration is 1 hour or more, otherwise MM:SS.
   * Note: similar to DurationFormattedHHMMSS, but automatically determines whether to include hours.
   *
   * @param ms
   * @constructor
   */
  static DurationAutoFormattedHHMMSS(ms: number | null | undefined): string {
    if (ms === null || ms === undefined) ms = 0;
    const seconds = ms / 1000;
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    if (hours > 0) {
      // If duration is 1 hour or more, use hh:mm:ss format
      return `${String(hours).padStart(2, '0')}:${String(mins).padStart(
        2,
        '0'
      )}:${String(secs).padStart(2, '0')}`;
    } else {
      // If duration is less than 1 hour, use mm:ss format
      return `${String(mins).padStart(2, '0')}:${String(secs).padStart(
        2,
        '0'
      )}`;
    }
  }

  static DurationFormattedHumanMinutes(
    ms: number | null | undefined,
    minLabel?: string
  ): string {
    const label = minLabel || 'min';
    const mins = !ms ? 0 : Math.round(ms / 1000 / 60);
    return `${mins} ${pluralize(label, mins)}`;
  }

  static AutoFormatMMSS(
    val: number,
    style: 'short' | 'long' = 'short',
    capitalize = false
  ) {
    let secLabel = style === 'short' ? 'sec' : 'second';
    let minLabel = style === 'short' ? 'min' : 'minute';
    if (capitalize) {
      secLabel = capitalizeFunc(secLabel);
      minLabel = capitalizeFunc(minLabel);
    }
    if (val < 60) {
      return `${val} ${pluralize(secLabel, val)}`;
    }
    const mins = Math.floor(val / 60);
    const secs = val % 60;
    let ret = `${mins} ${pluralize(minLabel, mins)}`;
    if (secs > 0) {
      ret += ` ${secs} ${pluralize(secLabel, secs)}`;
    }
    return ret;
  }

  static FormatMS(seconds: number) {
    if (seconds < 60) return `${seconds}s`;
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    let ret = `${m}m`;
    if (s > 0) {
      ret += ` ${s}s`;
    }
    return ret;
  }

  static IsWeekend(date: Date) {
    const day = getDay(date);
    return day === 0 || day === 6;
  }

  static IsWorkDay(date: Date) {
    return !this.IsWeekend(date);
  }

  static NextWorkDaySince(date: Date) {
    let next = date;
    while (true) {
      next = addDays(next, 1);
      if (this.IsWorkDay(next)) {
        return next;
      }
    }
  }

  static NextWorkDaySinceIncluding(date: Date) {
    if (this.IsWorkDay(date)) {
      return date;
    }
    return this.NextWorkDaySince(date);
  }

  static MaxDate(a: Date, b: Date) {
    return a >= b ? a : b;
  }

  static RoundToNearest(date: Date, nearestTo: number): Date {
    // note(falcon): date-fns version is buggy. see https://github.com/date-fns/date-fns/issues/3268
    const minutes = date.getMinutes() + date.getSeconds() / 60;
    const rounded = Math.ceil(minutes / nearestTo) * nearestTo;
    const result = new Date(date);
    result.setMinutes(rounded, 0, 0);
    return result;
  }

  static FormatElapsedTimeSecs(sec: number) {
    const seconds = Math.floor(sec % 60);
    const minutes = Math.floor((sec / 60) % 60);
    const hours = Math.floor(sec / 3600);

    if (hours > 0) {
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
        seconds < 10 ? '0' : ''
      }${seconds}`;
    }

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
}
